import { graphql, useStaticQuery } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';

import { Image } from '../types';

export const aboutData = (): { image: Image; text: string } => {
  const result = useStaticQuery(graphql`
    query {
      aboutImage: file(sourceInstanceName: { eq: "about" }) {
        id
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 700)
        }
      }
      text: file(sourceInstanceName: { eq: "about-text" }) {
        childMarkdownRemark {
          html
        }
      }
    }
  `);
  const image: Image = {
    key: result.aboutImage.id,
    src: getSrc(result.aboutImage.childImageSharp.gatsbyImageData) ?? '',
    gatsbyImage: result.aboutImage.childImageSharp.gatsbyImageData,
    originalName: result.aboutImage.name,
  };
  const text = result.text.childMarkdownRemark.html;
  return { image, text };
};
