import Hero from './components/hero';
import Instagram from './components/instagram';
import Layout from './components/layout';

import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { Helmet } from 'react-helmet';

import { aboutData } from '../graphql/about';

const About = () => {
  const data = aboutData();
  return (
    <Layout>
      <Helmet>
        <title>About - Petra Flucka Photography</title>
        <meta property="og:title" content="About - Petra Flucka Photography" />
        <meta property="og:image" content={data.image.src} />
        <meta property="og:url" content="https://petraflucka.com/about/" />
        <meta
          property="og:description"
          content="My name is Petra Flucka, and I’m a food and lifestyle photographer based in Vienna, Austria. From styling the scene to finding the right light to set the mood, I help my clients tell their stories through grabbing visuals that make the viewer stop and stare."
        />
      </Helmet>
      <div className="md:flex md:space-x-10 px-0 md:px-20">
        <div className="w-full md:w-2/5 pb-4 md:pb-0">
          <GatsbyImage image={data.image.gatsbyImage} alt="" />
        </div>
        <div className="w-full md:w-3/5 text-left" dangerouslySetInnerHTML={{ __html: data.text }} />
      </div>
      <div className="px-0 md:px-20">
        <Hero />
      </div>

      <Instagram />
    </Layout>
  );
};

export default About;
